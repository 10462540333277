// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'page_title',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'created_at',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'updated_at',
    Object: 'value',
    label: '',
    width: '150'
  }, {
    prop: 'deleted_at',
    label: '',
    Object: 'value',
    width: '150'
  }]
}
